import { format } from 'date-fns';
import { create } from 'zustand';

type State = {
  patientDetails: PatientDetails;
  appoinmentId?: number | null;
  duration?: number;
  tokenNo: string;
  dialogOpen: boolean;
  setPatientDetails: (patientDetails: PatientDetails) => void;
  setAppoinmentId: (appoinmentId: number | null) => void;
  setPatientId: (patientId: number | null) => void;
  setDuration: (duration: number) => void;
  setTokenNo: (tokenNo: string) => void;
  setDialogOpen: (dialogOpen: boolean) => void;
  clearAppoinment: () => void;
};

type PatientDetails = {
  first_name: string;
  last_name: string;
  age: number;
  phone: string;
  patient_id?: number | null;
};

const defaultPatientDetails: PatientDetails = {
  first_name: '',
  last_name: '',
  age: 0,
  phone: '',
  patient_id: null,
};

export const useLabAppoinment = create<State>((set) => ({
  patientDetails: defaultPatientDetails,
  appoinmentId: null,
  duration: undefined,
  tokenNo: '',
  dialogOpen: false,

  setPatientDetails: (patientDetails: PatientDetails) =>
    set({ patientDetails }),
  setAppoinmentId: (appoinmentId: number | null) => set({ appoinmentId }),
  setPatientId: (patientId: number | null) =>
    set((state) => ({
      ...state,
      patientDetails: { ...state.patientDetails, patient_id: patientId },
    })),
  setDuration: (duration: number) => set({ duration }),
  setTokenNo: (tokenNo: string) => set({ tokenNo }),
  setDialogOpen: (dialogOpen: boolean) => set({ dialogOpen }),
  clearAppoinment: () =>
    set({
      appoinmentId: null,
      patientDetails: defaultPatientDetails,
      tokenNo: '',
      duration: undefined,
    }),
}));

type LabStateAction = {
  labId: number | null;
  date: Date;
  setLabId: (labId: number) => void;
  setDate: (date: Date) => void;
  getDate: () => string;
};

export const useLab = create<LabStateAction>((set, get) => ({
  labId: null,
  date: new Date(),
  setLabId: (labId: number) => set({ labId }),
  setDate: (date: Date) => set({ date }),
  getDate: () => format(get().date, 'yyyy-MM-dd'),
}));
