import { useState, useEffect } from 'react';

import { Link, useLocation } from 'react-router-dom';
import logo from '@/assets/unq-logo-nav.png';
import { cn } from '@/lib/utils';
import { Menu, X } from 'lucide-react';

export let publicNavLinks = [
  { name: 'Home', link: '/' },
  { name: 'Clinic', link: '/clinic-login' },
  { name: 'Provider', link: '/provider-login' },
];

const Nav = () => {
  const { pathname } = useLocation();
  const isLiveTracking = pathname?.includes('/lv/');

  if (isLiveTracking) {
    publicNavLinks = [];
  }

  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 500) {
      setIsMobileView(false);
    }
  }, []);

  return (
    <header
      id="header"
      className={cn('md:!block', {
        '!hidden': isLiveTracking,
        'fixed-top mobile-nav-active': isMobileView,
        '': !isMobileView,
      })}
    >
      <div
        className={cn('d-flex align-items-center justify-content-between', {
          container: !isLiveTracking,
          'mx-9': isLiveTracking,
        })}
      >
        <button
          type="button"
          className="mobile-nav-toggle d-lg-none"
          onClick={() => setIsMobileView(!isMobileView)}
          style={{
            display: `${isLiveTracking ? 'none' : 'block'}`,
          }}
        >
          {isMobileView ? (
            <X className="size-4" />
          ) : (
            <Menu className="size-4" />
          )}
        </button>
        <a href="/" className="flex items-end ">
          <img
            alt="UnQ SCS"
            className="logo mr-auto"
            src={logo}
            style={{ height: '40px' }}
          />
          {isLiveTracking && (
            <span className="text-2xl text-sky-700 font-bold ml-3">
              Live Tracking
            </span>
          )}
        </a>
        <nav
          className={isMobileView ? 'mobile-nav' : 'nav-menu d-none d-lg-block'}
        >
          <ul>
            {publicNavLinks.map((route) => (
              <li
                key={route.link}
                className={pathname === route.link ? 'active' : ''}
                onClick={() => {
                  if (isMobileView) {
                    setIsMobileView(false);
                  }
                }}
              >
                <Link to={route.link}>{route.name}</Link>
              </li>
            ))}
          </ul>
        </nav>
        <div
          className="mobile-nav-overly"
          style={{ display: `${isMobileView ? 'block' : 'none'}` }}
        />
      </div>
    </header>
  );
};

export default Nav;
