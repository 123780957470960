import * as React from 'react';
import {
  CircleUserRound,
  Cog,
  FileArchiveIcon,
  FileAxis3D,
  FileBox,
  FileChartLine,
  FileUser,
  FlaskConical,
  Home,
  Hospital,
  LifeBuoy,
  Mouse,
  Send,
  Sheet,
  Stethoscope,
} from 'lucide-react';

import { NavMain } from '@/components/nav-main';
import { NavProjects } from '@/components/nav-projects';
import { NavUser } from '@/components/nav-user';
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  
} from '@/components/ui/sidebar';
import { useClinic, useFullUser } from '@/hooks/useUser';
import { STORAGE_DOMAIN } from '@/config/api';
import logo from '@/assets/unq-logo-nav.png';
import { AppoinmentDateLab, NavBarLabSelect } from '../pages/lab/navbarItem';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from './ui/select';

const data = {
  navMain: [
    {
      title: 'Home',
      url: '/home',
      icon: Home,
      isActive: true,
      items: [
        {
          title: 'Dashboard',
          url: '/dashboard',
        },
        {
          title: 'Web Checkin',
          url: '/webcheckin',
        },
      ],
    },
    {
      title: 'Patients',
      url: '/patient',
      icon: CircleUserRound,
    },
    {
      title: 'Booking',
      url: '/booking',
      icon: Mouse,
      items: [
        {
          title: 'Providers',
          url: '/booking/provider',
        },
        {
          title: 'Patients',
          url: '/booking/patient',
        },
        {
          title: 'Availability',
          url: '/booking/availability',
        },
      ],
    },
    {
      title: 'Consult',
      url: '/consult',
      icon: Stethoscope,
      items: [
        {
          title: 'Master List',
          url: '/consult/master-list',
        },
        {
          title: 'Recent List',
          url: '/consult/recentconsultations',
        },
        {
          title: 'Walkin List',
          url: '/consult/walkins',
        },
        {
          title: 'OP Checkin List',
          url: '/consult/appointments',
        },
        {
          title: 'OP Timesheet',
          url: '/consult/oplist',
        },
        {
          title: 'Transfer List',
          url: '/consult/transfers',
        },
      ],
    },
    {
      title: 'Lab',
      url: '/lab',
      icon: FlaskConical,
    },
    {
      title: 'Settings',
      url: '/settings',
      icon: Cog,
      items: [
        {
          title: 'Procedures',
          url: '/settings/procedures',
        },
        {
          title: 'Schedules',
          url: '/settings/schedules',
        },
        {
          title: 'Rules',
          url: '/settings/rules',
        },
        {
          title: 'Medicines',
          url: '/settings/medicines',
        },
        {
          title: 'Registration Fields',
          url: '/settings/registration_fields',
        },
        {
          title: 'Labs',
          url: '/settings/labs',
        },
      ],
    },
    {
      title: 'Reports',
      url: '/reports',
      icon: Sheet,
      items: [
        {
          title: 'Payments',
          url: '/reports/payments',
        },
        {
          title: 'Follow up List',
          url: '/reports/followuplist',
        },
      ],
    },
  ],
  navSecondary: [
    {
      title: 'Support',
      url: '#',
      icon: LifeBuoy,
    },
    {
      title: 'Feedback',
      url: '#',
      icon: Send,
    },
  ],
  projects: [
    {
      name: 'Recent List',
      url: '/consult/recentconsultations',
      icon: FileChartLine,
    },
    {
      name: 'Master List',
      url: '/consult/master-list',
      icon: FileArchiveIcon,
    },
    {
      name: 'WK List',
      url: '/consult/walkins',
      icon: FileAxis3D,
    },
    {
      name: 'OP List',
      url: '/consult/appointments',
      icon: FileBox,
    },
    {
      name: 'Patient List',
      url: '/patient',
      icon: FileUser,
    },
  ],
};

export const AppSidebar = React.memo(function AppSidebar({ ...props }: React.ComponentProps<typeof Sidebar>) {
  const { setSelectedClinic, getAllClinics, isProvider, user } = useFullUser();
  const clinic = useClinic();

  const clinicList = React.useMemo(
    () =>
      getAllClinics().map((c) => ({
        name: c.clinic_name,
        value: c.clinic_id.toString(),
        clinic_no: c.clinic_no,
      })),
    [getAllClinics]
  );

  const avatar =
    user?.type === 'provider'
      ? user.user_data.profile_pic
      : user?.type === 'clinic'
      ? user.user_data.image_url
      : null;

  const mainNav =
    user?.type === 'clinic'
      ? [
          ...data.navMain,
          {
            title: 'My Clinic',
            url: `/myclinic/${clinic?.clinic_id}`,
            icon: Hospital,
          },
        ]
      : data.navMain;



  return (
    <Sidebar collapsible="icon" variant="inset" {...props}>
      <SidebarHeader>
        <SidebarMenu>
          <SidebarMenuItem>
            <SidebarMenuButton
              size="lg"
              className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
            >
              <div className="group-data-[collapsible=icon]:flex hidden aspect-square size-8 items-center justify-center rounded-lg text-sidebar-primary-foreground group-data-[collapsible=icon]:bg-transparent bg-slate-50">
                <img src={logo} alt="UNQ" className="h-4" />
              </div>
              <div className="group-data-[collapsible=icon]:hidden flex aspect-square items-center justify-center rounded-lg text-sidebar-primary-foreground group-data-[collapsible=icon]:bg-transparent bg-slate-50">
                <img src={logo} alt="UNQ" className="h-8" />
              </div>
            </SidebarMenuButton>
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarHeader>
      <SidebarContent>
        <NavMain items={mainNav} />
        <NavProjects projects={data.projects} />
        <div className="flex flex-col gap-2 mt-auto px-0 pb-1">
          {!isProvider() && <NavBarLabSelect />}
          {!isProvider() && <AppoinmentDateLab />}

          {isProvider() && (
            <Select
              value={clinic?.clinic_id.toString()}
              onValueChange={(value) => setSelectedClinic(value)}
            >
              <SelectTrigger
                className="w-full text-foreground group-data-[collapsible=icon]:!px-4 group-data-[collapsible=icon]:border-0 group-data-[collapsible=icon]:shadow-none"
                value={clinic?.clinic_id}
              >
                <Hospital className="w-4 h-4 shrink-0 mr-1" />
                <SelectValue className='shrink' placeholder="Select Clinic" />
              </SelectTrigger>
              <SelectContent>
                {clinicList.map((c) => (
                  <SelectItem key={c.value} value={c.value}>
                    {c.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          )}
        </div>
        {/* <NavSecondary items={data.navSecondary} className="mt-auto" /> */}
      </SidebarContent>
      <SidebarFooter>
        <NavUser
          user={{
            name: user?.user_data.first_name + ' ' + user?.user_data.last_name,
            email: user?.user_data.user_no || '',
            avatar: `${STORAGE_DOMAIN}${avatar}`,
          }}
        />
      </SidebarFooter>
    </Sidebar>
  );
});
