import {
  createBrowserRouter,
  Outlet,
  RouteObject,
  RouterProvider,
} from 'react-router-dom';

import ErrorPage from './pages/error-page';

import LayoutClean from './components/layout/LayoutClean';
import LayoutFullScreen from './components/layout/LayoutFullScreen';
import PublicCleanLayout from './components/layout/PublicCleanLayout';

export const noNavList = [];

export const publicKioskRoutes: RouteObject[] = [
  //   { element: <PatientAdd />, path: '/patient/add' },
  //   {
  //     path: '/booking/availability',
  //     lazy: () => import('@/components/pages/booking-availability'),
  //   },
  //   {
  //     path: '/booking/patient',
  //     lazy: () => import('@/components/pages/booking-patient'),
  //   },
  //   {
  //     path: '/consult/oplist',
  //     lazy: () => import('@/components/pages/consult-op-list'),
  //   },
];

const cleanAuthenticatedRoutes: RouteObject[] = [
  {
    path: '/settings',
    lazy: () => import('@/pages/settings'),
  },
  {
    path: '/settings/rules',
    lazy: () => import('@/pages/settings/rules'),
  },
  {
    path: '/reports',
    lazy: () => import('@/pages/reports'),
  },
  {
    path: '/test',
    lazy: () => import('@/pages/test/index'),
  },
  { path: '/consult', lazy: () => import('@/pages/consult') },
  {
    path: '/settings/registration_fields',
    lazy: () => import('@/pages/settings/registration-fields'),
  },
  {
    path: '/settings/labs',
    lazy: () => import('@/pages/settings/labs'),
  },
  {
    path: '/settings/labs/:labId',
    lazy: () => import('@/pages/settings/labs-tests'),
  },
  {
    path: '/lab',
    lazy: () => import('@/pages/lab'),
  },
  {
    path: '/lab/:appoinmentId',
    lazy: () => import('@/pages/lab/tests'),
  },
  { path: '/patient', lazy: () => import('@/pages/patient-list') },
  {
    path: '/patient/list',
    lazy: () => import('@/pages/patient-list'),
  },
  {
    path: '/patient/edit/:patient_id',
    lazy: () => import('@/pages/patient-edit'),
  },
  {
    path: '/reports/payments',
    lazy: () => import('@/pages/report-payments'),
  },
  {
    lazy: () => import('@/pages/settings/medicines'),
    path: '/settings/medicines',
  },
  {
    lazy: () => import('@/pages/settings-procedures'),
    path: '/settings/procedures',
  },
  {
    lazy: () => import('@/pages/settings-schedules'),
    path: '/settings/schedules',
  },
  {
    path: '/consult/walkins',
    lazy: () => import('@/pages/consult-walkin-list'),
  },
  {
    path: '/consult/recentconsultations',
    lazy: () => import('@/pages/consult-recent-list'),
  },
  {
    path: '/consult/oplist',
    lazy: () => import('@/pages/consult-op-list'),
  },
  {
    path: '/consult/master-list',
    lazy: () => import('@/pages/consult-master-list'),
  },
  {
    path: '/consult/transfers',
    lazy: () => import('@/pages/consult-transfer-list'),
  },
  {
    path: '/consult/appointments',
    lazy: () => import('@/pages/consult-appoinments'),
  },
  {
    lazy: () => import('@/pages/reports-followup-list'),
    path: '/reports/followuplist',
  },
  {
    lazy: () => import('@/pages/myclinic'),
    path: '/myclinic/:id',
  },
  {
    path: '/clinic/view/:id',
    lazy: () => import('@/pages/myclinic'),
  },
  {
    path: '/booking/patient',
    lazy: () => import('@/pages/booking-patient'),
  },
  {
    path: '/booking/provider',
    lazy: () => import('@/pages/booking-provider'),
  },
  { path: '/home', lazy: () => import('@/pages/home') },
  { path: '/dashboard', lazy: () => import('@/pages/dashboard') },
  {
    path: '/booking/availability',
    lazy: () => import('@/pages/booking-new'),
  },
  {
    path: '/booking',
    lazy: () => import('@/pages/booking'),
  },
  {
    lazy: () => import('@/pages/patient-add'),
    path: '/patient/add',
  },
  {
    path: '/webcheckin',
    lazy: () => import('@/pages/web-checkin'),
  },
  {
    lazy: () => import('@/pages/provider-add'),
    path: '/provider/add/:clinic_id',
  },
  {
    path: '/provider/edit/:provider_id',
    lazy: () => import('@/pages/provider-add'),
  },
  {
    lazy: () => import('@/pages/clinic-add-or-edit'),
    path: '/clinic/edit/:clinic_id',
  },
  {
    lazy: () => import('@/pages/staff-add-edit'),
    path: '/clinic/frontdesk/add/:clinic_id',
  },
  {
    lazy: () => import('@/pages/staff-add-edit'),
    path: '/clinic/frontdesk/edit/:staffId/:clinic_id',
  },
  {
    path: '/consult/walk-in',
    lazy: () => import('@/pages/walkin-consult'),
  },
  {
    path: '/consult/op-session',
    lazy: () => import('@/pages/op-consult'),
  },
  {
    path: '/consult/edit/:appointment_id',
    lazy: () => import('@/pages/edit-casesheet'),
  },
];

const publicFullscreenRoutes: RouteObject[] = [
  {
    path: '/lab/public/:labId',
    lazy: () => import('@/pages/lab/public-display'),
  },
  {
    path: '/lab/public2/:labId',
    lazy: () => import('@/pages/lab/public-display2'),
  },
  {
    path: '/lab/token/:labId',
    lazy: () => import('@/pages/lab/token'),
  },
  {
    path: '/lab/lv/:appoinmentId',
    lazy: () => import('@/pages/lab/public-single-test'),
  },
  {
    path: '/lv/:partialUIN',
    lazy: () => import('@/pages/liveq-page'),
  },
  {
	path: '/i/:id',
	lazy: () => import('@/pages/url-share')
  }
];

const publicCleanRoutes: RouteObject[] = [
  {
    path: '/clinic',
    lazy: () => import('@/pages/clinic-add-or-edit'),
  },
  {
    path: '/clinic-login',
    lazy: () => import('@/pages/clinic-login'),
  },
  {
    lazy: () => import('@/pages/provider-login'),
    path: '/provider-login',
  },
  {
    lazy: () => import('@/pages/provider-add'),
    path: '/provider-new',
  },
  {
    lazy: () => import('@/pages/pharmacy-login'),
    path: '/pharmacy',
  },
  {
    path: '/forgot-password/:userType',
    lazy: () => import('@/pages/reset-password'),
  },
  {
    path: '/verify-email/:token',
    lazy: () => import('@/pages/verify-email'),
  },
  { lazy: () => import('@/pages/logout'), path: '/logout' },
  { path: '/', lazy: () => import('@/pages/landing') },
  {
    path: '/reset-password/:token',
    lazy: () => import('@/pages/verify-password'),
  },
];

function KioskLayout() {
  return (
    <div>
      <Outlet />
    </div>
  );
}

const router = createBrowserRouter([
  {
    element: <PublicCleanLayout />,
    errorElement: <ErrorPage />,
    children: publicCleanRoutes,
  },

  {
    element: <LayoutClean />,
    errorElement: <ErrorPage />,
    children: cleanAuthenticatedRoutes,
  },
  {
    element: <KioskLayout />,
    errorElement: <ErrorPage />,
    children: publicKioskRoutes,
  },
  {
    element: <LayoutFullScreen />,
    errorElement: <ErrorPage />,
    children: publicFullscreenRoutes,
  },
]);

function AllRoutes() {
  return <RouterProvider router={router} />;
}

export default AllRoutes;
