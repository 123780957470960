import { useLayoutEffect, memo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { AppSidebar } from '@/components/app-sidebar';
import { useNetworkState } from '@uidotdev/usehooks';
import { useClinic, useUser } from '@/hooks/useUser';
import { SidebarInset, SidebarProvider, SidebarTrigger } from '../ui/sidebar';
import { Separator } from '../ui/separator';
import { Breadcrumbs } from './page-header';
import { useNavBar } from '@/hooks/use-navbar';
import {
  LucideIcon,
  MessageSquareWarning,
  Pause,
  Wallet,
  WifiOff,
} from 'lucide-react';
import { isToday } from 'date-fns';
import { Toaster } from '../ui/toaster';

const useFullScreenLocation = () => {
  const location = useLocation();
  return ['/consult/walk-in', '/consult/op-session'].includes(location.pathname) ? true : false;
};

const LayoutClean = () => {
  const user = useUser();
  useLayoutEffect(() => {
    if (user === null) {
      console.log('user is null');
      window.location.href = '/';
    }
    if (user?.signedIn === false) {
      window.location.href = '/';
    }
  }, [user]);

  if (!user) return <div>Loading...</div>;

  return (
    <SidebarProvider>
      <AppSidebar />
      <SidebarInset>
        <NavBar />
        <div className="flex flex-1 flex-col gap-4 p-4 overflow-x-auto">
          <Outlet />
          <Toaster />
        </div>
      </SidebarInset>
    </SidebarProvider>
  );
};

export default LayoutClean;

const NavBar = memo(() => {
  const { title, description } = useNavBar();
  if (useFullScreenLocation()) return null;
  return (
    <header className="flex h-16 shrink-0 items-center gap-2 border-b px-4">
      <SidebarTrigger className="-ml-1" />
      <Separator orientation="vertical" className="mr-2 h-4" />
      <div className="flex items-center gap-2 group">
        <span className="text-sm font-bold">{title}</span>
        <Separator
          orientation="vertical"
          className="h-4 opacity-0 group-hover:opacity-100 transition-opacity duration-200 hidden lg:inline-block"
        />
        <span className="hidden lg:inline-block text-sm text-muted-foreground max-w-0 group-hover:max-w-[500px] overflow-hidden whitespace-nowrap transition-all duration-200">
          {description}
        </span>
      </div>
      <Separator orientation="vertical" className="mr-2 h-4" />
      <Breadcrumbs />
      <div className="ml-auto hidden lg:block">
        <Notifications />
      </div>
    </header>
  );
});

const Notifications = () => {
  const clinic = useClinic();
  const user = useUser();
  const networkState = useNetworkState();

  if (networkState.online === false) {
    return (
      <NotificationBadge icon={WifiOff}>
        You are offline. Please check your internet connection.
      </NotificationBadge>
    );
  }

  if (
    user.type !== 'pharmacy' &&
    user.preferences.paused_op &&
    isToday(new Date(user.preferences.paused_op.paused_at))
  ) {
    return (
      <NotificationBadge icon={Pause}>
        Your OP has been paused today
      </NotificationBadge>
    );
  }

  if (clinic?.wallet_balance && clinic.wallet_balance < 5) {
    return (
      <NotificationBadge icon={Wallet}>
        Your wallet balance is critically low.
      </NotificationBadge>
    );
  }

  return <div />;
};

const NotificationBadge = ({
  children,
  icon: Icon = MessageSquareWarning,
}: {
  children: React.ReactNode;
  icon?: LucideIcon;
}) => {
  return (
    <div className="text-rose-600 bg-rose-50 rounded p-2 leading-3 text-sm">
      <Icon className="size-4 inline-block mr-1" />
      {children}
    </div>
  );
};
