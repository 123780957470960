import { BACKEND_URL } from '@/config/api';
import TokenService from '../_services/TokenService';
import { useFullUser } from '@/hooks/useUser';
import { mutate } from 'swr';

export const postFetcher: any = async <T>([url, data]: [string, T]) => {
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${TokenService.getAccessToken()}`,
      },
      body: JSON.stringify(data),
    });

    if (response.status === 401) {
      throw new Error('Unauthorized');
    }
    return response.json();
  } catch (error: any) {
    const refreshToken = TokenService.getRefreshToken();
    if (error.message === 'Unauthorized') {
      if (refreshToken)
        try {
          const refreshResponse = await fetch(
            `${BACKEND_URL}/login/web_admin/auth_token`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ refresh_token: refreshToken }),
            }
          );

          if (refreshResponse.ok) {
            const tokens = await refreshResponse.json();
            console.log({ tokens });
            TokenService.setToken(tokens.tokens);

            // Retry the original request with the new access token
            const retryResponse = await fetch(url, {
              method: 'POST',
              cache: 'no-store',
              headers: {
                Authorization: `Bearer ${TokenService.getAccessToken()}`,
              },
              body: JSON.stringify(data),
            });

            if (retryResponse.ok) {
              return retryResponse.json();
            }
          }
        } catch (refreshError) {
          // Handle refresh token error
          console.error('Failed to refresh token:', refreshError);
        }
      // Redirect to sign-in page if retry failed or other errors occurred
      TokenService.clearToken();
      window.location.href = '/';
    }
    throw error;
  }
};

export const getFetcher = async (url: string) => {
  try {
    const response = await fetch(url, {
      cache: 'no-store',
      headers: { Authorization: `Bearer ${TokenService.getAccessToken()}` },
    });
    if (response.status === 401) {
      throw new Error('Unauthorized');
    }
    return response.json();
  } catch (error: any) {
    const refreshToken = TokenService.getRefreshToken();
    if (error.message === 'Unauthorized') {
      if (refreshToken) {
        try {
          const refreshResponse = await fetch(
            `${BACKEND_URL}/login/web_admin/auth_token`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ refresh_token: refreshToken }),
            }
          );

          if (refreshResponse.ok) {
            const tokens = await refreshResponse.json();
            console.log({ tokens });
            TokenService.setToken(tokens.tokens);

            // Retry the original request with the new access token
            const retryResponse = await fetch(url, {
              cache: 'no-store',
              headers: {
                Authorization: `Bearer ${TokenService.getAccessToken()}`,
              },
            });

            if (retryResponse.ok) {
              return retryResponse.json();
            }
          }
        } catch (refreshError) {
          // Handle refresh token error
          console.error('Failed to refresh token:', refreshError);
        }
      }

      // Redirect to sign-in page if retry failed or other errors occurred
      useFullUser.getState().logout();
      TokenService.clearToken();
      window.location.href = '/';
    }
    throw error;
  }
};

export const getFetcherWithCallback =
  (callback: (data: Promise<any>) => void) => async (url: string) => {
    const response = await fetch(url, {
      cache: 'no-store',
      headers: { Authorization: `Bearer ${TokenService.getAccessToken()}` },
    });
    return callback(response.json());
  };

export const mutatePrefix = (url: string) =>
  mutate(
    (e) => {
      return (
        (Array.isArray(e) && e[0].startsWith(url)) ||
        (typeof e === 'string' && e.startsWith(url))
      );
    },
    undefined,
    { revalidate: true }
  );
