import { useLayoutEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useUser } from '@/hooks/useUser';
import { Toaster } from '../ui/toaster';

const LayoutFullScreen = () => {
  const user = useUser();
  useLayoutEffect(() => {
    if (user === null) {
      console.log('user is null');
      window.location.href = '/';
    }
    if (user?.signedIn === false) {
      window.location.href = '/';
    }
  }, [user]);

  if (!user) return <div>Loading...</div>;

  return (
    <div className="flex flex-col w-full min-h-screen">
      <div className="bg-white flex flex-col grow">
        <Outlet />
        <Toaster />
      </div>
    </div>
  );
};

export default LayoutFullScreen;
